/**
 * The base styles of the application.
 */

/**
 * Import vendor libraries.
 */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "~sweetalert/dist/sweetalert.css";
@import "~ladda/dist/ladda-themeless.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~toastr/build/toastr.min.css";
@import "~datatables.net-fixedheader-dt/css/fixedHeader.dataTables.min.css";
@import "~x-editable/dist/bootstrap3-editable/css/bootstrap-editable.css";
@import "~clockpicker/dist/bootstrap-clockpicker.min.css";
@import "~clockpicker/dist/jquery-clockpicker.min.css";
@import "~swipebox/src/css/swipebox.min.css";

/**
 * Import local libraries.
 */
@import "../../libs/animate/css/animate.css";
@import "../../libs/datatables/css/datatables.css";
@import "../../libs/inspinia/css/inspinia";

/**
 * Import partials.
 */
@import "style";
@import "utils";
@import "custom";
@import "custom_font";
