.zi-10 {
    z-index: 10;
}

.blue-font-color {
    color: #0d8ddb;
}

.mt-1 {
    margin-top: 1%;
}
.color-red {
    color: red;
}

.color-green {
    color: green;
}

.color-orange {
    color: orange;
}

.hidden {
    display: none;
}

.full-width-el {
    width: 100%;
}

.mt-10 {
    margin-top: 10px;
}

.clear {
    clear: both;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.pos-abs {
    position: absolute;
}

.inline-block {
    display: inline-block;
}

.mb-1 {
    margin-bottom: 12px;
}

.h-85 {
    min-height: 85px !important;
}

.mt-20 {
    margin-top: 20px;
}
