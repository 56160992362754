.undo_transition {
    color: red;
}

.data_chained_city ~ .help-block {
    color: #a94442 !important;
}

/**
 * Cash on delivery template
 */
.cash-on-delivery-wrapper {
    margin: 10px;
    width: 98%;
}

.client-info {
    display: inline-block;
    margin: 5px;
}

.client-details {
    display: inline-block;
    margin: 5px;
    font-weight: bold;
}

.client-header {
    color: #2b76f2;
    margin: 5px;
}

/**
 * Awb template style
 */
.awb_wrapper {
    border: 1px solid;
    display: inline-block;
    position: relative;
    min-width: 810px;
    padding: 5px;
    width: 99%;
}

.awb_info_wrapper {
    border: 1px solid;
    display: inline-block;
    position: relative;
    width: 49%;
    margin-bottom: 5px;
    min-height: 110px;
}

.awb_info {
    display: inline-block;
    vertical-align: top;
}

.awb_info_floating {
    position: absolute;
    margin-left: 100px;
    margin-top: -35px;
}

.awb_logo {
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
}

.awb_small_text {
    font-size: small;
    margin: 0;
}

/**
 * class for tally template
 */
.total {
    margin-left: 400px;
    margin-top: -2px;
}

/**
 * Remove the default individual filters
 */
table.dataTable tfoot tr {
    display: none;
}

/**
 * Remove the default global searching filter
 */
.dataTables_wrapper .dataTables_filter {
    display: none;
}

/* navigation style updates*/
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level, .mini-navbar .nav li:focus > .nav-second-level {
    min-width: 180px;
}

/* log in page */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;

}

.logo {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 15px 15px;
    background: url(images/logo-small.png) #3a3f51 no-repeat center;
    text-indent: -9999px;
}

.logo-element {
    padding: 13px 0;
}


.login {
    margin: 100px auto 0 auto;
    width: 400px;
    padding: 15px 30px 30px 30px;
    overflow: hidden;
    background-color: #efefef;
    -webkit-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.2);
}

.login form {
    overflow: hidden;
}

.login .login-fields {
    border: solid 1px #e5e5e5;
    background-color: #fff;
    padding: 15px;
}

.login .login-fields .form-control {
    border: 0;
    background-color: #fff;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 5px 1px;
}

.login .form-control:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.login .login-fields #username {
    border-bottom: solid 1px #ddd;
}

.login .btn {
    background-color: #5d9cec;
    border: solid 1px #5d9cec;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;
    padding: 8px 12px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto;
    margin-top: 10px;
}

.login .btn:hover {
    background-color: #5d9cec;
}

.login input[type="checkbox"] {
    position: static;
    margin-left: 0;
    margin-top: 0 \9;
    float: left;
    margin-right: 3px;
}

.login .errors ul li {
    list-style: none;
}

.form-hint {
    font-size: 11px;
}

.terms, .password-reset {
    padding: 10px 0 0 0;
}

.password-reset a {
    color: #428bca;
}

.login label {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
}

.copyright {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    color: #eee;
    text-shadow: 0 1px 5px #000;
}

.login.reset #username {
    border-bottom: 0;
}

.login.reset .login-fields {
    padding: 5px 15px;
    margin-bottom: 15px;
}

.alert {
    margin: 15px 0 0 0;
    border-radius: 0;
    padding: 5px 15px;
}

/* 404 page */

.page404 {
    width: 700px;
    height: 252px;
    margin: -150px 0 0 -350px;
    position: absolute;
    left: 50%;
    top: 50%;
}

.page404 img {
    float: right;
    padding-top: 60px;
}

.page404 .error-general {
    padding-top: 30px;
}

.page404 h1 {
    font-size: 60px;
}

.page404 p {
    font-size: 24px;
    font-family: Roboto;
    font-weight: 400;
    color: #444;
    padding: 10px 0 20px 0;
}

.page404 .btn-success {
    border-radius: 0;
}

/* end 404 page */

/* start navigation styles*/
.nav-header {
    padding: 8px 40px;
}

/* end navigation styles*/

/* start select2 styles */
.select2-container {
    z-index: inherit;
    width: 100% !important;
}

.option-adder-add + .select2-container {
    width: 92% !important;
}

.select2-container .select2-selection--single,
.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container .select2-selection--single .select2-selection__arrow {
    height: 34px;
}

.select2-container--default.select2-container .select2-selection--single, .select2-container--default.select2-container .select2-selection--multiple {
    border: 1px solid #e5e6e7;
    border-radius: 0;
}

.select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--open .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple {
    border-color: #1ab394;
}

.select2-dropdown {
    z-index: 2300;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 14px;
    line-height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #555;
}

/* end select2 styles */

/* start breadcrumbs styles*/
.breadcrumb {
    line-height: 34px;
    font-size: 14px;
}

.breadcrumb-wrapper .table-actions {
    margin-top: 2px;
}

@media only screen and (max-width: 480px) {

    .copyright {
        position: static;
        padding-top: 40px;
    }

    .login {
        width: 300px;
        margin-top: 30px;
        padding: 15px;
    }

    .password-reset.pull-right {
        float: none !important;
    }

    .terms, .password-reset, .copyright {
        width: 100%;
        display: block;
    }

    .password-reset a {
        line-height: 36px;
    }

    .page404 {
        width: 80%;
        position: static;
        margin: 0 auto;
        text-align: center;
        padding-top: 30px;
    }

    .page404 img,
    .page404 .error-general {
        float: none;
        padding-top: 0;
        width: 100%;
        height: auto;
    }

    .page404 img {
        max-width: 360px;
    }

    .page404 h1 {
        font-size: 50px;
        line-height: 46px;
    }

    .page404 .error-general {
        max-width: 180px;
    }

}

/* Option adder widget styles */
.option-adder-add {
    float: right;
}

/* end Option adder widget styles */


/* datatable reports */
.report_expandable {
    cursor: pointer;
}

.grid-report-level-1 {
    background-color: #e4d9e0 !important;
}

.grid-report-level-2 {
    background-color: #d1c6de !important;
}

.grid-report-level-3 {
    background-color: #c9d2d0 !important;
}

/* end datatable reports */

/* clock picker css */
.popover {
    z-index: 2201; /* in order to show it in add/edit modal form */
}

/* end clock picker css */

/* datetime picker css */
.bootstrap-datetimepicker-widget .btn {
    background-color: white;
    border-color: none;
    color: black;
}

.bootstrap-datetimepicker-widget .btn:hover {
    background-color: white;
    color: black;
}

.datepicker-dropdown:after, .datepicker-dropdown:before {
    content: none;
}

/* end datetime picker css */

/* datatable editable */
.datepicker > div {
    display: block; /*bootstrap-editable library hides datepicker*/
}

.editable-input input {
    padding: 0 24px 0 2px;
    font-size: 12px;
    height: 24px;
}

.editable-buttons .btn {
    padding: 4px 7px;
    font-size: 9px;
}

.editable-buttons .editable-cancel {
    margin-left: 4px;
}

span.editable {
    border-bottom-color: grey;
}

.editable-click:hover {
    cursor: pointer;
}

.editable-click:after {
    content: "\f040";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-left: 5px;
}

#sg-datatables-contractor .table.dataTable td:last-child {
    text-align: left;
}

.column-right .editable-error-block.help-block {
    text-align: left;
}

.editable-empty,
.editable-empty:hover,
.editable-empty:focus {
    color: #676a6c !important;
}

/* end datatable editable */

/* start fixed column */

th.fixed_column, th.fixed_column_header {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
}

th.fixed_column {
    background: #e6ffff !important;
}

.grid-report-level-1 th.fixed_column {
    background: #ffffe6 !important;
}

.grid-report-level-2 th.fixed_column {
    background: #FFDCC6 !important;
}

.grid-report-level-3 th.fixed_column {
    background: #D6B3CA !important;
}

th.fixed_column_header {
    z-index: 1;
}

thead .fixed_column {
    z-index: 1 !important;
}

.fixedHeader-floating {
    z-index: 1;
}

tbody .report_expandable.fixed_column:before {
    content: "\f107";
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    padding: 0 3px;
    font-size: 16px;
}

tbody .report_expandable.fixed_column:after {
    content: "";
    position: absolute;
    top: 0;
    left: 25px;
    display: block;
    height: 100%;
    background-color: #e8e8e8;
}

.sticky_header {
    position: sticky;
    top: 0;
}

.grid_sticky_header > thead th {
    position: sticky !important;
    top: 0;
    z-index: 3;
}

.grid_sticky_header > thead th.fixed_column_header{
    z-index: 4;
}

.bg-red, .bg-red .report_expandable {
    background-color: #ff9999 !important;
}

.bg-green {
    background-color: #E2EFD9 !important;
}

.bg-orange {
    background-color: #ffc085 !important;
}

.bg-yellow {
    background-color: #fff78e !important;
}

.border-grey td {
    border-color: #777 !important;
}

/* end fixed column */
