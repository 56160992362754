$__iconfont__data: map-merge(if(global_variable_exists('__iconfont__data'), $__iconfont__data, ()), (
	"iconfont": (
		"add": "\ea01",
		"calculator": "\ea02",
		"canceled": "\ea03",
		"close": "\ea04",
		"collected-return": "\ea05",
		"damaged": "\ea06",
		"delivered": "\ea07",
		"delivery-return": "\ea08",
		"download-a4": "\ea09",
		"download-a6": "\ea0a",
		"edit": "\ea0b",
		"pick-up": "\ea0c",
		"print-a4": "\ea0d",
		"print-a6": "\ea0e",
		"redirect": "\ea0f",
		"retries": "\ea10",
		"return": "\ea11"
	)
));


$create-font-face: true !default; // should the @font-face tag get created?

// should there be a custom class for each icon? will be .filename
$create-icon-classes: true !default;

// what is the common class name that icons share? in this case icons need to have .icon.filename in their classes
// this requires you to have 2 classes on each icon html element, but reduced redeclaration of the font family
// for each icon
$icon-common-class: 'custom-icon' !default;

// if you whish to prefix your filenames, here you can do so.
// if this string stays empty, your classes will use the filename, for example
// an icon called star.svg will result in a class called .star
// if you use the prefix to be 'icon-' it would result in .icon-star
$icon-prefix: 'custom-icon-' !default;

// helper function to get the correct font group
@function iconfont-group($group: null) {
    @if (null == $group) {
        $group: nth(map-keys($__iconfont__data), 1);
    }
    @if (false == map-has-key($__iconfont__data, $group)) {
        @warn 'Undefined Iconfont Family!';
        @return ();
    }
    @return map-get($__iconfont__data, $group);
}

// helper function to get the correct icon of a group
@function iconfont-item($name) {
    $slash: str-index($name, '/');
    $group: null;
    @if ($slash) {
        $group: str-slice($name, 0, $slash - 1);
        $name: str-slice($name, $slash + 1);
    } @else {
        $group: nth(map-keys($__iconfont__data), 1);
    }
    $group: iconfont-group($group);
    @if (false == map-has-key($group, $name)) {
        @warn 'Undefined Iconfont Glyph!';
        @return '';
    }
    @return map-get($group, $name);
}

// complete mixing to include the icon
// usage:
// .my_icon{ @include iconfont('star') }
@mixin iconfont($icon) {
    font-family: "custom_iconfont";
    font-style: normal;
    font-weight: 400;
    content: iconfont-item($icon);
}

// creates the font face tag if the variable is set to true (default)
@if $create-font-face == true{
    @font-face {
     font-family: "custom_iconfont";
     src: url('../../../public/build/front/fonts/iconfont.eot'); /* IE9 Compat Modes */
     src: url('../../../public/build/front/fonts/iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../../../public/build/front/fonts/iconfont.woff') format('woff'), /* Pretty Modern Browsers */
          url('../../../public/build/front/fonts/iconfont.ttf')  format('truetype'), /* Safari, Android, iOS */
          url('../../../public/build/front/fonts/iconfont.svg') format('svg'); /* Legacy iOS */
     
    }
}

// creates icon classes for each individual loaded svg (default)
@if $create-icon-classes == true{
    [class^="#{$icon-prefix}"],
    [class*="#{$icon-prefix}"]{
        &:before{
            font-family: "custom_iconfont";
            font-style: normal;
            font-weight: 1001;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    @each $family, $map in $__iconfont__data {
        @each $icon, $content in $map {
            .#{$icon-prefix}#{$icon}{
                &:before{
                    content: iconfont-item($icon);
                }
            }
        }
    }
}
