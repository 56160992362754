@import "iconfont_iconfont";

@font-face {
  font-family: 'locker';
  src: url('fonts/locker.eot?43223383');
  src: url('fonts/locker.eot?43223383#iefix') format('embedded-opentype'),
       url('fonts/locker.woff2?43223383') format('woff2'),
       url('fonts/locker.woff?43223383') format('woff'),
       url('fonts/locker.ttf?43223383') format('truetype'),
       url('fonts/locker.svg?43223383#locker') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-locker:before {
  font-family: "locker";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
 
.icon-locker:before { content: '\e800'; } /* '' */