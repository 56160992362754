/* CR design */
/* CR design */

/* globals */

body {
  background-color: #3d3d3d;
}

label {
  font-weight: 600;
}

table.fuelIndexTable td {width:25%; text-align: center;vertical-align: center}

.wrapper-content {
  padding: 20px 0;
  padding-bottom: 40px;
}

input[data-show-datepicker=true] {
  background-image: url(images/calendar-icon.png);
  background-size: 34px;
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 42px !important;
  cursor: pointer;
}

.btn {
  padding: 4px 12px;
  background-color: #353535;
  border-color: #353535;
  color: #ffff;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
}

.btn.focus,
.btn:focus,
.btn:hover {
  background-color: #656363;
  border-color: #656363;
  color: #fff;
}

.btn.btn-primary {
  background-color: #a00a0f;
  border-color: #a00a0f;
  color: #fff;
}

.btn.btn-primary.focus,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background-color: #ed1f24;
  border-color: #ed1f24;
  color: #fff;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #d0babb;
  border-color: #d0babb;
}

.modal-form.form-horizontal .align-text {
  padding-left: 150px;
}

@media all and (max-width: 767px) {
  .modal-form.form-horizontal .align-text {
    padding-left: 0;
  }
}

#show-all-filters {
  background-color: transparent;
  border: none;
  color: #ff1e23;
  font-size: 13px;
  line-height: 1;
  font-weight: 700;
  padding-right: 0;
  margin-right: 0;
  margin-top: 5px;
  position: relative;
  padding-right: 10px;
}

.red-border {
    border: 1px solid red;
}

@media all and (max-width: 767px) {
  #show-all-filters {
    float: none !important;
    margin-top: 0px;
    margin-bottom: 3px;
    margin-left: 3px;
    padding-left: 0;
  }
}

#show-all-filters:after {
  font-family: "FontAwesome";
  content: "\f105";
  position: absolute;
  right: 0;
  top: 6px;
}

#show-all-filters:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}

@media all and (max-width: 767px) {
  .dataTables_length {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .dataTables_paginate {
    clear: left;
    float: left;
  }
}

/* sidebar */
nav.navbar-static-side {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  max-height: 100%;
  overflow: auto;
  background-image: url('images/sidebar-bg.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: 1000;
}

nav.navbar-static-side .sidebar-collapse {
  margin-bottom: 120px;
}

/*custom scrollbar*/
.mCSB_scrollTools {
  width: 10px;
}
.mCSB_inside>.mCSB_container {
  margin-right: 0;
}
.mCSB_scrollTools .mCSB_draggerRail {
  display: none;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(255,255,255,.50);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}


nav.navbar-static-side .nav {
  background: #3d3d3d;
}

li.nav-header {
  border: none;
}

.nav-header {
  text-align: center;
  background: #353535;
  padding: 10px 20px;
}

.nav > li > a {
  color: #b0b0b0;
  padding: 14px 20px 14px 15px;
}

.nav > li > a i {
  width: 18px;
  text-align: center;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #353535;
  color: white;
}

.nav > li {
  border-left: 4px solid transparent;
}

.nav > li.active {
  border-left: 4px solid #ff1e23;
  background: #353535;
}

ul.nav-second-level {
  background: #353535;
}

ul.nav-second-level i {
  display: none;
}

.nav-second-level li a {
  padding: 7px 20px 7px 10px;
  padding-left: 40px;
}

.metismenu .fa.arrow:before {
  content: "\f107 ";
}

.metismenu .active > a > .fa.arrow:before {
  content: "\f106 ";
}

.nav-third-level li {
  border-left: none;
}

.nav-third-level li a {
  padding: 3px 20px;
  padding-left: 50px;
}

.nav.nav-second-level > li.active {
  border-left: 4px solid transparent;
}

body.mini-navbar nav.navbar-static-side {
  overflow: visible;
}

body.mini-navbar .nav-header {
  border: none;
  background-color: #353535;
}

body.mini-navbar .logo-element {
  text-align: center;
}

body.mini-navbar .navbar-default .nav > li > a {
  text-align: center;
}
body.mini-navbar .mCustomScrollBox, body.mini-navbar .mCSB_container {
  overflow: visible;
}
body.mini-navbar .mCSB_container {
  z-index: 200;
  padding-bottom: 40px;
}
body.mini-navbar .mCSB_scrollTools .mCSB_dragger {
  visibility: hidden;
  opacity: 0;
}

#menuFilter {
  text-align: center;
  padding: 4px 0;
  position: relative;
}
#menuFilter:after {
  display: table;
  content: "";
  clear: both;
}

#menuFilter input {
  padding: 0 10px;
  height: 24px;
  background: rgba(255, 255, 255, 0.10);
  border-color: rgba(255, 255, 255, 0.25);
  font-size: 12px;
  line-height: 1.8em;
  color: #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#menuFilter button {
  padding: 3px 6px;
  font-size: 12px;
  position: absolute;
  right: 12px;
}

body.mini-navbar #menuFilter {
  display: none;
}

.filter.highlight {
  background: rgba(255, 193, 7, 0.25);
  border-top: 1px solid #ffc107;
  border-bottom: 1px solid #ffc107;
}

/* top header */
.minimalize-styl-2 {
  margin-top: 10px;
  margin-left: 15px;
}

.navbar-static-top {
  border: none;
}

.nav.navbar-top-links a {
  font-size: 13px;
  font-weight: 600;
  padding: 14px 5px;
  color: #353535 !important;
}

.nav.navbar-top-links a:hover {
  color: #a00a0f !important;
}

.nav.navbar-top-links a i {
  margin: 0;
  width: auto;
}

.navbar-top-links li:last-child {
  margin-right: 25px;
}

body.mini-navbar .nav .nav-second-level {
  background-color: #353535;
  padding: 0;
}

body.mini-navbar .navbar-default .nav > li > a {
  text-align: left;
}

body.mini-navbar .navbar-default .nav > li > a {
  text-align: center;
  padding: 14px 15px;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  text-align: left;
}

nav.navbar-static-side::-webkit-scrollbar {
  width: 0;
  background-color: #6b6b6b;
}

nav.navbar-static-side::-webkit-scrollbar-thumb {
  background-color: #505050;
}

.nav.nav-second-level.collapse, .nav.nav-second-level.collapsing {
  background-color: #353535;
}

.nav.nav-third-level.collapse, .nav.nav-third-level.collapsing {
  background-color: #353535;
}

li.nav-header {
  border-bottom: 1px solid #000;
}

.nav.nav-second-level > li.active {
  background-color: #4f4f4f;
}
.nav.nav-second-level > li.active > a:hover, .nav.nav-second-level > li.active > a:focus {
  background-color: #4f4f4f;
}
.nav.nav-second-level > li > a:hover, .nav.nav-second-level > li > a:focus {
  background-color: #3d3d3d;
}

.nav.nav-third-level > li.active {
  background-color: #4f4f4f;
}
.nav.nav-third-level > li.active > a:hover, .nav.nav-third-level > li.active > a:focus {
  background-color: #4f4f4f;
}
.nav.nav-third-level > li > a:hover, .nav.nav-third-level > li > a:focus {
  background-color: #3d3d3d;
}

/* breadcrumb */
.breadcrumb-wrapper .breadcrumb {
  font-size: 13px;
}

.breadcrumb-wrapper .m-b-xs {
  text-align: right;
  margin: 0;
}

@media all and (max-width: 767px) {
  .breadcrumb-wrapper .m-b-xs {
    text-align: left;
    margin-bottom: 5px;
  }
}

.breadcrumb-wrapper {
  padding: 7px 5px;
  margin: 0;
}

/* footer */
.footer {
  background-color: transparent;
  text-align: right;
  z-index: -1;
}

@media all and (max-width: 767px) {
  .footer {
    text-align: center;
  }
}

.has-shadow {
  -webkit-box-shadow: 0 0 6px rgba(0,0,0,.1);
  -moz-box-shadow: 0 0 6px rgba(0,0,0,.1);
  box-shadow: 0 0 6px rgba(0,0,0,.1);
}

/* filters */
#grid-filters {
  margin-bottom: 15px;
}

#grid-filters .form-horizontal .control-label {
  display: none;
}

#grid-filters .form-control {
  margin: 7px 0;
}

#grid-filters .ibox-content {
  padding: 5px 10px;
  -webkit-box-shadow: 0 0 6px rgba(0,0,0,.1);
  -moz-box-shadow: 0 0 6px rgba(0,0,0,.1);
  box-shadow: 0 0 6px rgba(0,0,0,.1);
}

#grid-filters .grid-filters-row {
  padding: 3px 0;
  margin-left: 0;
  margin-right: 0;
}

#grid-filters .grid-filters-buttons {
  padding-bottom: 7px;
}

.form-control:focus, .single-line:focus {
  border-color: #353535;
}

#grid-filters.has-hidden-filters .col-lg-3:not(.visible-filter) {
  display: none;
}

#grid-filters .select2-container--default .select2-search--inline .select2-search__field {
  text-transform: capitalize;
}

#grid-filters span.select2 {
  margin-top: 7px;
  margin-bottom: 7px;
}

#grid-filters .select2-container .select2-selection--multiple {
  /*min-height: 34px;*/
}

#grid-filters .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--multiple {
  border-color: #353535;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #353535;
}

.custom-hidden,
.custom-hidden + .select2 {
  display: none !important;
}

/* tables */
.table {
  max-width: calc(100% - 1px);
}

.table > thead > tr > th, .table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 1.1;
  vertical-align: middle;
}

table.dataTable {
  -webkit-box-shadow: 0 0 6px rgba(0,0,0,.1);
  -moz-box-shadow: 0 0 6px rgba(0,0,0,.1);
  box-shadow: 0 0 6px rgba(0,0,0,.1);
}

table.dataTable tbody tr td {
  padding: 10px 15px;
}

.table > thead > tr > th {
  background-color: #f8f8f8 !important;
  border-bottom: 2px solid #a00a0f !important;
  vertical-align: middle;
  line-height: 1.1;
  padding: 14px 15px;
  font-size: 13px;
  text-align: center !important;
}

table.dataTable > thead > tr > th {
  padding-right: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
}

table.dataTable > thead > tr > th:hover,
table.dataTable > thead > tr > th.sorting_asc,
table.dataTable > thead > tr > th.sorting_desc {
  background-color: #fff !important;
}

table.dataTable > thead > tr > th.sorting_disabled:hover {
  background-color: #f8f8f8 !important;
  cursor: text;
}

.table-striped > tbody > tr {
  background-color: #f8f8f8;
}

.table-striped > tbody > tr.odd {
  background-color: #fff;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  font-family: "FontAwesome";
  content: "\f106";
  position: absolute;
  right: 7px;
  top: 50%;
  margin-top: -8px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 7px;
  top: 50%;
  margin-top: -2px;
  opacity: 0.2;
}

table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before {
  color: #a00a0f;
  opacity: 1;
}

.dataTable td a.grd-edit-modal.btn {
  border: none !important;
  text-transform: uppercase;
  font-weight: bold;
  color: #000 !important;
  font-size: 12px;
  line-height: 1.2;
  padding: 0;
  margin: 8px 0;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.dataTable td a.grd-edit-modal.btn:before {
  content: "\f105";
  font-family: "FontAwesome";
  float: right;
  margin-left: 5px;
}

.dataTable td a.grd-edit-modal.btn:hover {
  color: #a00a0f !important;
  background-color: transparent;
  box-shadow: none;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.column-left {
  text-align: left !important;
}

.column-center {
  text-align: center !important;
}

.column-right {
  text-align: right !important;
}

.dataTables_wrapper {
  overflow: auto;
  padding-bottom: 15px;
}

.dataTables_wrapper .collapse-all-tables {
  position: fixed;
  z-index: 6;
  left: 235px;
  bottom: 10px;
  top: auto;
  line-height: 1;
  box-shadow: 0px 4px 9px 0px #8a8a8a;
  padding-top: 14px;
}

.dataTables_wrapper .collapse-all-tables i {
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: -7px;
}

@media all and (max-width: 767px) {
  .dataTables_wrapper .collapse-all-tables {
    left: auto;
    right: 15px;
  }
}

/* tables multiple rows */
.grid-report-level-1 .dataTables_wrapper {
  padding: 0;
}

.grid-report-level-1 > td,
.grid-report-level-2 > td,
.grid-report-level-3 > td {
  background-color: #f3f3f4;
  text-align: left !important;
}

.grid-report-level-2 > td {
  background-color: #e4e4e7 !important;
}

.grid-report-level-1 .table-bordered > thead > tr > th {
  margin: 0;
}

.grid-report-level-1 .bottom {
  padding: 5px 0;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  height: auto;
  position: static;
}

td.report_expandable {
  position: relative;
  padding-right: 25px !important;
  vertical-align: middle !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
}

td.report_expandable:before {
  content: "\f107";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  padding: 0 8px;
  font-size: 16px;
}

td.report_expandable:after {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #e8e8e8;
}

td.report_expandable:hover,
td.report_expandable.opened {
  background-color: #fdf8f4;
}

td.report_expandable.opened:before {
  content: "\f106";
}

/* table.dataTable tbody tr td:last-child {
padding-top: 0;
} */

.grid-report-level-1 .table-bordered {
  border-top: none;
  margin: 0;
}

table.dataTable tbody tr > td:first-child {
  padding-left: 25px;
  padding-right: 25px;
}

table.dataTable tbody tr.grid-report-level-2 > td:first-child {
  padding-bottom: 0;
}

table.dataTable tbody tr.grid-report-level-1 > td:last-child,
table.dataTable tbody tr.grid-report-level-2 > td:last-child {
  padding-top: 0;
  padding-bottom: 0;
}

table.dataTable tbody tr td.report_expandable {
  padding-left: 40px;
}

.dataTables_wrapper .dataTables_wrapper {
  overflow: visible;
}

.dataTables_paginate a {
  display: inline-block;
  vertical-align: middle;
}

/* alert */

.sweet-alert {
  border-radius: 0;
  padding-bottom: 35px;
}

.sweet-alert h2 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 0;
}

.sweet-alert p {
  font-size: 13px;
  line-height: 18px;
}

.sweet-alert .error-info {
    text-align: left;
    padding-left: 30px;
    display: block;
}

.sweet-alert .sa-button-container {
  display: flex;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
  flex-direction: row-reverse;
}

.sweet-alert .sa-button-container .sa-confirm-button-container {
  /* float: left; */
}

.sweet-alert .sa-button-container .sa-confirm-button-container button.confirm,
.sweet-alert .sa-button-container button.cancel {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  border: 1px solid transparent;
  color: #fff;
  box-shadow: none !important;
}

.sweet-alert .sa-button-container .sa-confirm-button-container button.confirm {
  background-color: #a00a0f !important;
  border-color: #a00a0f;
}

.sweet-alert .sa-button-container .sa-confirm-button-container button.confirm:hover {
  background-color: #ed1f24 !important;
  border-color: #ed1f24;
  color: #fff;
}

.sweet-alert .sa-button-container button.cancel {
  float: right;
}

/* action buttons */
.action-button {
  color: #000;
  background-color: transparent;
  border-radius: 0;
  border: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1;
  margin: 0 3px;
}

.action-button:hover {
  color: #a00a0f;
  background-color: transparent;
}

.dataTable .action-button {
  display: block;
  max-width: 30px;
  margin: auto;
  padding: 2px 0;
}

/* modal */
.inmodal .modal-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 100%;
  max-width: 800px;
  margin: 0 !important;
}

.inmodal.large .modal-dialog {
  max-width: 1200px;
}

.inmodal.extra-large .modal-dialog {
  max-width: 1400px;
}

.inmodal .modal-content {
  height: 100%;
}

.inmodal .modal-header {
  padding: 10px 30px;
  text-align: center;
  background-color: #3d3d3d;
}

@media all and (max-width: 1199px) {
  .inmodal .modal-header {
    padding: 10px 25px;
  }
}

@media all and (max-width: 767px) {
  .inmodal .modal-header {
    padding: 10px 15px;
  }
}


.inmodal .modal-header .close {
  text-shadow: none;
  color: #fff;
  margin-top: 2px;
  font-size: 23px;
  line-height: 1;
  display: block;
  opacity: 1;
  margin-right: 3px;
}

.inmodal .modal-header .close:hover {
  color: #bbb;
}

.inmodal .modal-title {
  font-size: 14px;
  line-height: 27px;
  color: #fff;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

.inmodal .modal-body {
  height: calc(100% - 95px);
  overflow-x: hidden;
  background: #ffffff;
  padding: 0 30px;
}

@media all and (max-width: 1199px) {
  .inmodal .modal-body {
    padding: 0 25px 30px 25px;
  }
}

@media all and (max-width: 767px) {
  .inmodal .modal-body {
    padding: 0 15px 30px 15px;
  }
}

/* .modal.inmodal input:first-child + fieldset.form-horizontal,
.modal.inmodal fieldset.form-horizontal:first-child {
margin-top: -20px;
} */

.inmodal .modal-footer {
  background-color: #f3f3f4;
  padding: 7px 30px;
  text-align: left;
}

@media all and (max-width: 767px) {
  .inmodal .modal-footer {
    padding: 7px 15px;
  }
}

/* action buttons */
.action-button {
  color: #000;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1;
  margin: 0 3px;
}

.action-button:hover {
  color: #a00a0f;
}

/* modal form */

/* .form-horizontal label.control-label {
font-size: 12px;
line-height: 12px;
font-weight: 400;
display: inline-block;
vertical-align: middle;
padding: 0;
padding-top: 4px;
/* modal form */

.form-horizontal legend {
  font-size: 13px;
  line-height: 14px;
  color: #000;
  border: none;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
}

.form-horizontal legend label.control-label {
  font-size: 13px;
  line-height: 14px;
  color: #000;
  border: none;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
  font-style: normal;
}

.form-horizontal hr {
  margin-bottom: 15px;
  margin-top: 5px;
}

.form-horizontal h2 {
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  color: #000;
  background-color: #f3f3f4;
  border-bottom: 1px solid #cfcfcf;
  margin: 0 -30px;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  -webkit-transition: 0.15s all ease-in-out;
  -moz-transition: 0.15s all ease-in-out;
  position: relative;
}

@media all and (max-width: 1199px) {
  .form-horizontal h2 {
    margin: 0 -30px;
  }
}

.form-horizontal h2 a {
  display: block;
  color: #000;
  padding: 12px 30px;
}

.form-horizontal h2 a:before {
  content: "\f067";
  font-family: "FontAwesome";
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  float: right;
  display: block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
}

.form-horizontal h2 a.collapsed:before {
  content: "\f067";
}

.form-horizontal h2 a.collapsed.in:before,
.form-horizontal h2 a[aria-expanded=true]:before {
  content: "\f068";
}

.form-horizontal h2:hover {
  background-color: #ededed;
}

.form-horizontal .form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-horizontal label.control-label {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  padding-top: 8px;
  margin-bottom: 5px;
  padding-right: 8px;
  width: 100%;
}

.form-horizontal label.control-label.required:after {
  content: "*";
  margin-left: 2px;
  color: #ed5565;
  margin-right: -8px;
  position: absolute;
}

.form-horizontal div.control-label {
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  width: 150px;
  float: left;
}

.form-horizontal div.control-label + div {
  width: calc(100% - 150px);
  float: left;
}

.custom-col-width div.control-label {
  width: 95px;
}

.custom-col-width div.control-label + div {
  width: calc(100% - 95px);
}

.select2-white-space-break .select2-selection__choice {
  width: 100%;
}

.select2-white-space-break .select2-container .select2-selection--multiple .select2-selection__rendered {
  white-space: break-spaces;
}

@media all and (max-width: 767px) {
  .form-horizontal div.control-label,
  .form-horizontal div.control-label + div {
    width: 100%;
  }
}

.form-horizontal .form-control,
.form-horizontal  .single-line {
  padding: 2px 10px;
  font-size: 13px;
  line-height: 15px;
  height: 28px;
  max-width: 100%;
}

.form-horizontal span.help-block {
  margin: 0;
  margin-top: 1px;
  margin-bottom: -4px;
  font-size: 11px;
}

.form-horizontal span.help-block ul.list-unstyled {
  margin: 0;
  padding: 0;
}

.form-horizontal .select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 26px;
  min-height: initial !important;
}

.form-horizontal .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}

.form-horizontal .select2-container--default .select2-selection--multiple .select2-selection__choice,
.form-horizontal .select2-container .select2-search--inline .select2-search__field {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.form-horizontal .select2-container .select2-selection--single,
.form-horizontal .select2-container .select2-selection--single .select2-selection__rendered,
.form-horizontal .select2-container .select2-selection--single .select2-selection__arrow,
.form-horizontal .select2-container .select2-selection--multiple {
  height: auto;
  min-height: 28px;
}

.form-horizontal .checkbox, .form-horizontal .checkbox-inline,
.form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 4px;
}

.form-horizontal .form-group .checkbox {
  padding: 0 15px;
}

/* client modal */
#client-entity #client-entity_deliveryWeight > div {
  display: inline-block;
  vertical-align: middle;
}

#client-entity #client-entity_deliveryWeight > div:first-child {
  margin-right: 25px;
}

#client-entity #identification-data .form-group[data-validation=county],
#client-entity #identification-data .form-group[data-validation=city] {
  margin: 0 -15px;
  margin-bottom: 10px;
}

#client-entity .client-type-toggle.checkboxes + .form-group,
#client-entity .client-type-toggle.checkboxes + .form-group + .form-group {
  float: left;
  width: 50%;
}

#client-entity .client-type-toggle.checkboxes + .form-group {
  margin-right: 25px;
}

#client-entity .widgetItem .clearfix > .form-group:first-child {
  margin-top: 5px;
  margin-bottom: 5px;
}

.inmodal li.widgetItem {
  background-color: #f3f3f4;
  padding: 12px 20px;
  margin: 1px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.inmodal li.widgetItem .form-group button.glyphicon-remove {
  background: none;
  border: none;
  color: #000;
  font-size: 13px;
}

.inmodal li.widgetItem .form-group button.glyphicon-remove:hover,
.inmodal li.widgetItem .form-group button.glyphicon-remove:focus {
  color: #696969;
  box-shadow: none;
}

@media all and (max-width: 767px) {
  .inmodal li.widgetItem {
    padding: 12px 15px;
    margin-left: -15px;
    margin-right: -15px;
    width: auto !important;
    display: block;
  }
}

.inmodal li.widgetItem:first-child {
  margin-top: 10px;
}

.inmodal .subcollection {
  padding: 0 20px;
  margin-top: 15px;
  background: none;
  background-color: #e4e4e7;
  max-width: 1238px;
}

@media all and (max-width: 767px) {
  .inmodal .subcollection {
    padding: 0 15px;
  }
}

.inmodal .templateWidgetAdd.add-person {
  background-color: #353535;
  border-color: #353535;
  font-size: 13px;
}

.inmodal .templateWidgetAdd.add-person:hover,
.inmodal .templateWidgetAdd.add-person:focus {
  background-color: #000;
  border-color: #000;
}

.inmodal .templateWidgetAdd.add-person:before {
  content: "\f067";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-size: 11px;
}

#client-entity #clientPickupPointContactPerson li.widgetItem {
  margin-top: 0px;
  margin-bottom: 13px;
  border-top: 1px solid #d6d6d6;
  padding-top: 3px;
}

#client-entity #clientPickupPointContactPerson > li.widgetItem:first-child {
  border-top: none;
  margin-top: 5px;
}

#client-entity .widgetItem div[data-validation=county] > div,
#client-entity .widgetItem div[data-validation=city] > div,
#client-entity .widgetItem div[data-validation=address] > div,
#client-entity .widgetItem div[data-validation=status] > div,
#client-entity .widgetItem div[data-validation=alias] > div,
#client-entity .widgetItem div[data-validation=name] > div,
#client-entity .widgetItem div[data-validation=position] > div,
#client-entity .widgetItem div[data-validation=phoneNumber] > div,
#client-entity .widgetItem div[data-validation=email] > div,
#client-entity .widgetItem div[data-validation=seller] > div {
  width: auto !important;
  float: none;
  padding: 0;
}

#client-entity .widgetItem div[data-validation=county],
#client-entity .widgetItem div[data-validation=city],
#client-entity .widgetItem div[data-validation=address],
#client-entity .widgetItem div[data-validation=status],
#client-entity .widgetItem div[data-validation=seller],
#client-entity .widgetItem div[data-validation=alias] {
  float: left;
  width: 12%;
  padding-right: 20px;
}

#client-entity .widgetItem div[data-validation=name],
#client-entity .widgetItem div[data-validation=position],
#client-entity .widgetItem div[data-validation=phoneNumber],
#client-entity .widgetItem div[data-validation=email] {
  float: left;
  width: 17%;
  padding-right: 20px;
}

#client-entity .widgetItem div[data-validation=status] {
  width: calc(20% - 50px);
}

#client-entity .widgetItem div[data-validation=alias], #client-entity .widgetItem div[data-validation=email] {
  padding-right: 0;
}

#client-entity .widgetItem div[data-validation=address] {
  width: 30%;
}

#client-entity .widgetItem div[data-validation=alias] + .form-group,
#client-entity .widgetItem div[data-validation=email] + .form-group + .form-group {
  float: right;
  margin: 0;
  margin-top: 23px;
}

#client-entity .widgetItem div[data-validation=alias] + .form-group button,
#client-entity .widgetItem div[data-validation=email] + .form-group + .form-group button {
  background: none;
  border: none;
  color: #000;
  font-size: 13px;
}

#client-entity .widgetItem div[data-validation=alias] + .form-group button:hover,
#client-entity .widgetItem div[data-validation=email] + .form-group + .form-group button:hover,
#client-entity .widgetItem div[data-validation=alias] + .form-group button:focus,
#client-entity .widgetItem div[data-validation=email] + .form-group + .form-group button:focus {
  color: #696969;
  box-shadow: none;
}

#client-entity .widgetItem div[data-validation=email] + .form-group {
  float: left;
  width: 17%;
  font-size: 12px;
  line-height: 1;
  margin-top: 26px;
  margin-bottom: 0;
}

#client-entity .widgetItem div[data-validation=email] + .form-group .checkbox input[type=checkbox] {
  margin-top: 1px;
}

#client-entity .widgetItem div[data-validation=alias] {
  padding-right: 5px;
}

#client-entity .widgetItem > .form-group:first-child {
  float: left;
  width: 100%;
}

#client-entity .widgetItem {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
}

#client-entity button.templateWidgetAdd.add-another-address {
  position: absolute;
  right: 78px;
  top: -36px;
  z-index: 3;
  font-size: 13px;
}

#client-entity button.templateWidgetAdd.add-another-address:before {
  content: "\f067";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-size: 11px;
}

#client-entity button.templateWidgetAdd.add-person {
  position: absolute;
  right: 63px;
  top: 9px;
}

#client-entity #contract-data div[data-validation=address] {
  margin-bottom: 0;
}

@media all and (max-width: 991px) {
  #client-entity .widgetItem div[data-validation=county],
  #client-entity .widgetItem div[data-validation=city] {
    width: 50%;
  }

  #client-entity .widgetItem div[data-validation=city] {
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=address] {
    width: 50%;
  }

  #client-entity .widgetItem div[data-validation=status] {
    width: calc(25% - 40px);
  }

  #client-entity .widgetItem div[data-validation=seller] {
    width: 25%;
  }

  #client-entity .widgetItem div[data-validation=alias] {
    width: 25%;
  }

  #client-entity button.templateWidgetAdd.add-person {
    right: 21px;
  }

  #client-entity .widgetItem div[data-validation=name],
  #client-entity .widgetItem div[data-validation=position] {
    width: 50%;
  }

  #client-entity .widgetItem div[data-validation=position] {
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=phoneNumber] {
    width: calc(39% - 40px);
  }

  #client-entity .widgetItem div[data-validation=email] {
    width: 40%;
  }

  #client-entity .widgetItem div[data-validation=email] + .form-group {
    width: 25%;
  }

  #client-entity #contract-data div[data-validation=county] {
    margin-bottom: 0;
  }

}

@media all and (max-width: 767px) {
  #client-entity .widgetItem div[data-validation=county],
  #client-entity .widgetItem div[data-validation=city] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=city] {
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=address] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=status] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=seller] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=alias] {
    width: calc(100% - 40px);
    padding-right: 0;
  }

  #client-entity .widgetItem .clearfix > .form-group:first-child {
    margin-bottom: 45px;
  }

  #client-entity button.templateWidgetAdd.add-person {
    left: 15px;
    top: 50px;
  }

  #client-entity .widgetItem div[data-validation=name], #client-entity .widgetItem div[data-validation=position] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=phoneNumber] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=email] {
    width: 100%;
    padding-right: 0;
  }

  #client-entity .widgetItem div[data-validation=email] + .form-group {
    width: 90%;
    margin-top: 20px;
  }

  #client-entity .widgetItem div[data-validation=email] + .form-group .checkbox input[type=checkbox] {
    margin-top: -1px;
  }

  #client-entity .widgetItem div[data-validation=email] + .form-group + .form-group {
    margin-top: 10px;
  }

  #client-entity button.templateWidgetAdd.add-another-address {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }
}

#contact-data li.widgetItem:first-child {
  margin-top: 10px;
}

#contact-data li.widgetItem {
  background-color: #f3f3f4;
  padding: 12px 20px;
  margin: 1px 0;
}

#contact-data .widgetItem div[data-validation=email] + .form-group {
  float: right;
  margin: 0;
  margin-top: 23px;
  width: auto;
}

#contact-data .widgetItem div[data-validation=email] + .form-group button{
  background: none;
  border: none;
  color: #000;
  font-size: 13px;
}

#contact-data .widgetItem div[data-validation=email] + .form-group button:hover,
#contact-data .widgetItem div[data-validation=email] + .form-group button:focus {
  color: #696969;
  box-shadow: none;
}

#contact-data .widgetItem div[data-validation=name] > div,
#contact-data .widgetItem div[data-validation=position] > div,
#contact-data .widgetItem div[data-validation=phoneNumber] > div,
#contact-data .widgetItem div[data-validation=email] > div {
  width: auto !important;
  float: none;
  padding: 0;
}

#contact-data .widgetItem div[data-validation=name],
#contact-data .widgetItem div[data-validation=position],
#contact-data .widgetItem div[data-validation=phoneNumber],
#contact-data .widgetItem div[data-validation=email] {
  float: left;
  width: 25%;
  padding-right: 20px;
}


#contact-data .widgetItem div[data-validation=phoneNumber] {
  width: calc(25% - 43px);
}

#contact-data .widgetItem div[data-validation=email] {
  padding-right: 0;
}

#contact-data button.templateWidgetAdd.add-contact-person {
  position: absolute;
  right: 78px;
  top: -36px;
  z-index: 3;
  font-size: 13px;
}

#contact-data button.templateWidgetAdd.add-contact-person:before {
  content: "\f067";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-size: 11px;
}

@media all and (max-width: 991px) {
  #contact-data .widgetItem div[data-validation=name],
  #contact-data .widgetItem div[data-validation=position],
  #contact-data .widgetItem div[data-validation=phoneNumber],
  #contact-data .widgetItem div[data-validation=email] {
    width: 50%;
  }

  #contact-data .widgetItem div[data-validation=position] {
    padding-right: 0;
  }

  #contact-data .widgetItem div[data-validation=email] {
    width: calc(50% - 40px);
  }
}

@media all and (max-width: 767px) {
  #contact-data li.widgetItem {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }

  #contact-data button.templateWidgetAdd.add-contact-person {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }

  #contact-data .widgetItem div[data-validation=name],
  #contact-data .widgetItem div[data-validation=position],
  #contact-data .widgetItem div[data-validation=phoneNumber],
  #contact-data .widgetItem div[data-validation=email] {
    width: 100%;
    padding-right: 0;
  }

  #contact-data .widgetItem div[data-validation=email] {
    width: calc(100% - 40px);
  }
}

/* service associations */

.client-service-item {
  display: table-row;
  width: 100%;
}

@media all and (max-width: 767px) {
  .client-service-item {
    display: block;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
}

.client-service-item > * {
  display: table-cell;
  vertical-align: middle;
  padding-right: 15px;
  padding-bottom: 10px;
}

@media all and (max-width: 767px) {
  .client-service-item > * {
    display: block;
    width: 100%;
    padding-left: 15px;
    margin: 0;
  }
}

.client-service-item > * > * {
  width: 100%;
  float: none !important;
  padding: 0;
}

.client-service-item .form-group {
  min-width: 145px;
}

@media all and (max-width: 767px) {
  .client-service-item .form-group {
    margin: 0;
  }
}

.client-service-item > a {
  padding-right: 0;
  line-height: 1;
}

.client-service-item:first-child > * {
  padding-top: 10px;
}

.client-service-item div[data-validation=tariffPlan] .col-lg-2 {
  width: 300px;
  margin: 0;
}

.client-service-item div[data-validation=discount] .col-lg-2 {
    width: 95px;
    margin: 0;
}

.client-service-item .future-tariff-plan {
    padding-left: 15px;
}



/* user roles */
.user-permisions {
  padding-left: 15px;
  padding-right: 15px;
}

.user-permisions #role-entity_permissions p.permissions-resource-label {
  display: block;
  float: left;
  width: 200px;
  margin: 0;
  text-decoration: none;
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 15px;
  cursor: pointer;
}

@media all and (max-width: 767px) {
  .user-permisions #role-entity_permissions p.permissions-resource-label {
    float: none;
    width: 100%;
  }
}

.user-permisions #role-entity_permissions .permision-options {
  width: calc(100% - 200px);
  float: left;
  margin: 0;
}

@media all and (max-width: 767px) {
  .user-permisions #role-entity_permissions .permision-options {
    float: none;
    width: 100%;
  }
}

.user-permisions #role-entity_permissions li.permissions-resource {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.user-permisions #role-entity_permissions ul.permissions-resources {
  margin-left: -40px;
  margin-right: -40px;
}

.user-permisions #role-entity_permissions li.permissions-resource {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 5px 40px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #fff;
  transition: all 0.10s ease-in-out;
  -webkit-transition: all 0.10s ease-in-out;
  -moz-transition: all 0.10s ease-in-out;
  position: relative;
}

.user-permisions #role-entity_permissions li.permissions-resource:hover {
  background-color: #f2f2f2;
}

.user-permisions #role-entity_permissions .checkbox {
  padding-top: 0;
  min-height: initial;
}

.user-permisions #role-entity_permissions li.permissions-group {
  margin-top: 0;
}

.user-permisions #role-entity_permissions span.check-all-label {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  position: absolute;
  left: 40px;
  top: 10px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
}

.user-permisions #role-entity_permissions span.check-all-label:before {
  content: "";
  display: block;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #353535;
  position: absolute;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  opacity: 0;
}

.user-permisions #role-entity_permissions .check-all.has-checks span.check-all-label:before {
  opacity: 1;
}

.user-permisions #role-entity_permissions .check-all.all-checks span.check-all-label:before {
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

/* logistics flow */

#logistic-flow-entity .form-group[data-validation=name] .col-lg-3,
#logistic-flow-entity .form-group[data-validation=status] .col-lg-3,
#logistic-flow-entity .form-group[data-validation=defaultFlow] .col-lg-3,
#logistic-flow-entity .form-group[data-validation=services] .col-lg-3 {
  width: 100px;
  float: left;
}

#logistic-flow-entity .form-group[data-validation=name] .col-lg-9,
#logistic-flow-entity .form-group[data-validation=status] .col-lg-9,
#logistic-flow-entity .form-group[data-validation=defaultFlow] .col-lg-9,
#logistic-flow-entity .form-group[data-validation=services] .col-lg-9 {
  width: calc(100% - 100px);
  float: left;
}

@media all and (max-width: 550px) {
  #logistic-flow-entity .form-group[data-validation=name] .col-lg-3,
  #logistic-flow-entity .form-group[data-validation=status] .col-lg-3,
  #logistic-flow-entity .form-group[data-validation=defaultFlow] .col-lg-3,
  #logistic-flow-entity .form-group[data-validation=services] .col-lg-3,
  #logistic-flow-entity .form-group[data-validation=name] .col-lg-9,
  #logistic-flow-entity .form-group[data-validation=status] .col-lg-9,
  #logistic-flow-entity .form-group[data-validation=defaultFlow] .col-lg-9,
  #logistic-flow-entity .form-group[data-validation=services] .col-lg-9 {
    width: 100%;
  }
}


#logistic-flow-entity .widgetItem .col-lg-3 {
  width: 100% !important;
  padding: 0;
}

#logistic-flow-entity ul.widgetTemplateRequired {
  margin-top: 5px;
  display: table;
  width: 100%;
}

@media all and (max-width: 991px) {
  #logistic-flow-entity ul.widgetTemplateRequired {
    display: block;
  }
}

#logistic-flow-entity li.widgetItem {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
}

@media all and (max-width: 991px) {
  #logistic-flow-entity li.widgetItem {
    border-bottom: 1px solid #e5e6e7;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
}

#logistic-flow-entity li.widgetItem > * {
  padding-right: 15px;
  float: left;
}

@media all and (max-width: 991px) {
  #logistic-flow-entity li.widgetItem > * {
    display: block;
    float: left;
    width: 50%;
  }
}

@media all and (max-width: 991px) {
  #logistic-flow-entity li.widgetItem > * {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  #logistic-flow-entity div[data-validation=roles] + .form-group {
    width: calc(50% - 45px);
    float: left;
  }

  #logistic-flow-entity div[data-validation=roles] + .form-group + .form-group {
    float: right;
    width: auto;
  }
}

@media all and (max-width: 767px) {
  #logistic-flow-entity li.widgetItem > * {
    padding-right: 0;
  }

  #logistic-flow-entity div[data-validation=roles] + .form-group {
    width: calc(100% - 45px);
  }

}


#logistic-flow-entity li.widgetItem div[data-validation=fromStatus],
#logistic-flow-entity li.widgetItem div[data-validation=toStatus] {
  width: 280px;
  max-width: 280px;
}

#logistic-flow-entity li.widgetItem div[data-validation=roles] {
  width: 290px;
}

@media all and (max-width: 1199px) {

  #logistic-flow-entity li.widgetItem div[data-validation=roles] {
    width: 210px;
  }

  #logistic-flow-entity .form-group .action-button {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 991px) {
  #logistic-flow-entity li.widgetItem div[data-validation=fromStatus],
  #logistic-flow-entity li.widgetItem div[data-validation=toStatus] {
    width: 50%;
    max-width: 50%;
  }

  #logistic-flow-entity li.widgetItem div[data-validation=roles] {
    min-width: initial;
    width: calc(100% - 200px);
    margin-top: 10px;
  }

  #logistic-flow-entity li.widgetItem div[data-validation=toStatus] {
    padding-right: 0;
  }
}

@media all and (max-width: 767px) {
  #logistic-flow-entity li.widgetItem div[data-validation=fromStatus],
  #logistic-flow-entity li.widgetItem div[data-validation=toStatus] {
    max-width: 100%;
    width: 100%;
  }
  #logistic-flow-entity li.widgetItem div[data-validation=toStatus] {
    margin-top: 10px;
  }
}

#logistic-flow-entity li.widgetItem div[data-validation=roles] + .form-group {
  max-width: 171px;
  font-size: 12px;
  line-height: 1;
  margin-top: 1px;
  margin-bottom: 0;
  padding-bottom: 0;
}

#logistic-flow-entity li.widgetItem div[data-validation=roles] + .form-group + .form-group {
  margin-top: 0px;
  padding: 0;
  margin-bottom: 0;
}

@media all and (max-width: 991px) {
  #logistic-flow-entity li.widgetItem div[data-validation=roles] + .form-group {
    margin-top: 11px;
  }
  #logistic-flow-entity li.widgetItem div[data-validation=roles] + .form-group + .form-group {
    margin-top: 8px;
  }
}

@media all and (max-width: 767px) {
  #logistic-flow-entity li.widgetItem div[data-validation=roles] {
    width: 100%;
  }

  #logistic-flow-entity li.widgetItem div[data-validation=roles] + .form-group .checkbox {
    padding-left: 0;
  }
}

#logistic-flow-entity li.widgetItem label.control-label {
  display: none;
}

/* user */
#user-entity div[data-validation=idSeries] {
  width: 120px;
  float: left;
  margin-left: 15px;
  margin-right: 0;
  margin-top: 0;
}

#user-entity div[data-validation=idSeries] .control-label {
  display: none;
}

#user-entity div[data-validation=idSeries] .col-lg-9 {
  width: auto;
  padding-right: 0;
}

#user-entity div[data-validation=idNumber] {
  width: calc(100% - 135px);
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

#user-entity div[data-validation=idNumber] .control-label {
  position: absolute;
  left: 7px;
}

#user-entity div[data-validation=idNumber] .col-lg-9 {
  width: auto;
  padding-right: 0;
  float: none;
}

#user-entity div[data-validation=pickupPoint] .select2-container {
  width: calc(100% - 50px) !important;
  float: left;
}

#transfer_seller div[data-validation=pickupPoint] .select2-container {
  width: calc(100% - 50px) !important;
}

#user-entity div[data-validation=pickupPoint] .btn.btn-primary {
  padding: 3px 6px;
  float: left;
  margin-left: 8px;
  top: 0;
  text-align: center;
}

/* login panel */
div#login-box-panel {
  background-color: #444343;
  padding: 50px 70px;
  width: 100%;
  max-width: 500px;
  position: relative;
  top: 50%;
  margin-top: -310px;
  border-radius: 4px;
}

div#login-box-panel .sameday-logo-intro {
  margin-bottom: 30px;
}

div#login-box-panel .password-reset a {
  color: #e6e6e6;
  margin-left: 20px;
}

div#login-box-panel .password-reset a:hover {
  color: #d8b4b4;
}

div#login-box-panel p.copyright.m-t {
  text-align: center;
  position: static;
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 14px;
  text-shadow: none;
  color: #8c8c8c;
}

/* tariff plan */
#tariff-plan-entity > div.m-t-md.row > .col-lg-4 {
  width: 50%;
  margin: auto;
}
#tariff-plan-entity > div.m-t-md.row > .col-lg-4 .col-lg-9 {
  width: calc(100% - 150px) !important;
}

/* ledger list */

.form-info-box {
  background-color: #f3f3f4;
  margin-left: -30px;
  margin-right: -30px;
  padding: 15px 30px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

@media all and (max-width: 767px) {
  .form-info-box {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px;
  }
}

.invoice-info > p {
  overflow: hidden;
  padding: 5px 0;
  margin: 0;
}

.invoice-info legend {
  width: 90px;
  float: left;
  margin: 0;
}

@media all and (max-width: 767px) {
  .invoice-info legend {
    width: 70px;
  }
}

.invoice-info legend + span {
  float: left;
  width: calc(100% - 150px);
  padding-left: 25px;
  line-height: 14px;
}


@media all and (max-width: 767px) {
  .invoice-info legend + span {
    width: calc(100% - 70px);
    padding-left: 15px;
  }
}

.create-invoice legend {
  margin-bottom: 0;
}

.create-invoice .form-group {
  margin-left: 0;
  margin-right: 0;
}

.invoice-total > * {
  margin: 0 !important;
}

fieldset.modal-form.form-horizontal.create-invoice {
  margin-top: 10px;
}

/* tarrif plan */

#tariff-plan-entity .widgetItem div[data-validation=start] > div,
#tariff-plan-entity .widgetItem div[data-validation=end] > div,
#tariff-plan-entity .widgetItem div[data-validation=price] > div,
#tariff-plan-entity .widgetItem div[data-validation=nationalPrice] > div {
  width: auto !important;
  float: none;
  padding: 0;
}

#tariff-plan-entity .widgetItem div[data-validation=start],
#tariff-plan-entity .widgetItem div[data-validation=end],
#tariff-plan-entity .widgetItem div[data-validation=price],
#tariff-plan-entity .widgetItem div[data-validation=nationalPrice] {
  float: left;
  width: 25%;
  padding-right: 20px;
}

#tariff-plan-entity .widgetItem div[data-validation=nationalPrice] {
  width: calc(25% - 40px);
}

#tariff-plan-entity .widgetItem div[data-validation=nationalPrice] + .form-group {
  float: right;
  margin: 0;
}

#tariff-plan-entity fieldset fieldset {
  position: relative;
}

#tariff-plan-entity fieldset legend {
  margin-top: 10px;
  float: left;
  clear: left;
}

#tariff-plan-entity button.templateWidgetAdd {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 3;
  background-color: #353535;
  border-color: #353535;
  font-size: 13px;
}

#tariff-plan-entity button.templateWidgetAdd:before {
  content: "\f067";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-size: 11px;
}

#tariff-plan-entity button.templateWidgetAdd:hover {
  background-color: #000;
  border-color: #000;
}


#tariff-plan-entity .widgetItem div[data-validation=name] > div,
#tariff-plan-entity .widgetItem div[data-validation=tax] > div,
#tariff-plan-entity .widgetItem div[data-validation=priceType] > div,
#tariff-plan-entity .widgetItem div[data-validation=logisticCircleName] > div,
#tariff-plan-entity .widgetItem div[data-validation=cost] > div
{
  width: auto !important;
  float: none;
  padding: 0;
}

#tariff-plan-entity .widgetItem div[data-validation=name],
#tariff-plan-entity .widgetItem div[data-validation=tax],
#tariff-plan-entity .widgetItem div[data-validation=priceType],
#tariff-plan-entity .widgetItem div[data-validation=logisticCircleName],
#tariff-plan-entity .widgetItem div[data-validation=cost]
{
  float: left;
  width: 33.33%;
  padding-right: 20px;
}

#tariff-plan-entity .widgetItem div[data-validation=tax] {
  width: calc(33.33% - 40px);
}

#tariff-plan-entity .widgetItem div[data-validation=priceType] + .form-group {
  float: right;
  margin: 0;
}

#tariff-plan-entity .additional-taxes .form-group:before,
#tariff-plan-entity .additional-taxes .form-group:after {
  content: none !important;
}

#tariff-plan-entity .package-ranges ul.widgetTemplateRequired {
  margin-bottom: -5px;
}

#tariff-plan-entity .additional-params .col-sm-6 + .col-sm-6 .control-label {
  width: 250px;
}

#tariff-plan-entity .additional-params .col-sm-6 + .col-sm-6 .control-label + div {
  width: calc(100% - 250px);
}

#tariff-plan-entity .additional-params .col-sm-6:first-child .control-label,
#tariff-plan-entity .additional-taxes .col-sm-8 .control-label {
  width: 210px;
}

#tariff-plan-entity .additional-params .col-sm-6:first-child .control-label + div,
#tariff-plan-entity .additional-taxes .col-sm-8 .control-label + div {
  width: calc(100% - 210px);
}

#tariff-plan-entity .additional-taxes .col-sm-4 .col-lg-9 {
  width: 100%;
}

#tariff-plan-entity .form-horizontal fieldset .widgetItem label.control-label {
  display: none;
}

#tariff-plan-entity .form-horizontal .widgetHeading {
  padding: 0 20px;
}

#tariff-plan-entity .form-horizontal .widgetHeading > * {
  float: left;
  width: 33.33% !important;
  margin-bottom: 0;
  margin-top: 5px;
}

#tariff-plan-entity .form-horizontal .package-ranges .widgetHeading > *,
#tariff-plan-entity .form-horizontal .envelope-ranges .widgetHeading > *,
#tariff-plan-entity .form-horizontal .large-package-ranges .widgetHeading > * {
  width: 25% !important;
}

#tariff-plan-entity li.widgetItem:first-child {
  margin-top: 5px;
}

#tariff-plan-entity .form-horizontal .service-taxes .widgetHeading > label.control-label:first-child + label.control-label {
  width: calc(33.33% - 40px) !important;
}

#tariff-plan-entity li.widgetItem {
  padding: 6px 20px;
  padding-bottom: 5px;
}

@media all and (max-width: 767px) {
  #tariff-plan-entity .additional-params .col-sm-6:first-child .control-label,
  #tariff-plan-entity .additional-taxes .col-sm-8 .control-label,
  #tariff-plan-entity .additional-params .col-sm-6:first-child .control-label + div,
  #tariff-plan-entity .additional-taxes .col-sm-8 .control-label + div,
  #tariff-plan-entity .additional-params .col-sm-6 + .col-sm-6 .control-label,
  #tariff-plan-entity .additional-params .col-sm-6 + .col-sm-6 .control-label + div,
  #tariff-plan-entity .widgetItem div[data-validation=start],
  #tariff-plan-entity .widgetItem div[data-validation=end],
  #tariff-plan-entity .widgetItem div[data-validation=price],
  #tariff-plan-entity .widgetItem div[data-validation=nationalPrice],
  #tariff-plan-entity .widgetItem div[data-validation=name],
  #tariff-plan-entity .widgetItem div[data-validation=tax],
  #tariff-plan-entity .widgetItem div[data-validation=priceType] {
    width: 100%;
    float: none;
  }

  #tariff-plan-entity .widgetItem div[data-validation=price],
  #tariff-plan-entity .widgetItem div[data-validation=priceType] {
    width: calc(100% - 40px);
  }

  #tariff-plan-entity .widgetItem div[data-validation=price],
  #tariff-plan-entity .widgetItem div[data-validation=priceType] {
    float: left;
  }

  #tariff-plan-entity .widgetItem div[data-validation=start],
  #tariff-plan-entity .widgetItem div[data-validation=end],
  #tariff-plan-entity .widgetItem div[data-validation=price],
  #tariff-plan-entity .widgetItem div[data-validation=nationalPrice],
  #tariff-plan-entity .widgetItem div[data-validation=name],
  #tariff-plan-entity .widgetItem div[data-validation=tax],
  #tariff-plan-entity .widgetItem div[data-validation=priceType] {
    padding-right: 0;
  }

  #tariff-plan-entity button.templateWidgetAdd {
    top: 9px;
  }

  #tariff-plan-entity li.widgetItem {
    overflow: hidden;
  }

  #tariff-plan-entity fieldset legend {
    margin-bottom: 5px;
  }

  #tariff-plan-entity .form-horizontal .widgetHeading {
    display: none;
  }

  #tariff-plan-entity .widgetItem div[data-validation=tax] {
    margin: 5px 0;
  }
}

/* fuel index entity */

#fuel-index-entity .widgetItem div[data-validation=start] > div,
#fuel-index-entity .widgetItem div[data-validation=end] > div,
#fuel-index-entity .widgetItem div[data-validation=indexPercentLocker] > div,
#fuel-index-entity .widgetItem div[data-validation=indexPercentNovs] > div,
#fuel-index-entity .widgetItem div[data-validation=indexPercentOvs] > div {
    width: auto !important;
    float: none;
    padding: 0;
}

#fuel-index-entity .widgetItem div[data-validation=start],
#fuel-index-entity .widgetItem div[data-validation=end],
#fuel-index-entity .widgetItem div[data-validation=indexPercentLocker],
#fuel-index-entity .widgetItem div[data-validation=indexPercentOvs],
#fuel-index-entity .widgetItem div[data-validation=indexPercentNovs] {
    float: left;
    width: 18%;
    padding-right: 20px;
}

#fuel-index-entity fieldset fieldset {
    position: relative;
}

#fuel-index-entity fieldset legend {
    margin-top: 10px;
    float: left;
    clear: left;
}

#fuel-index-entity .widgetItem .form-group {
    float: right;
    margin: 0;
}

#fuel-index-entity button.templateWidgetAdd {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 3;
    background-color: #353535;
    border-color: #353535;
    font-size: 13px;
}

#fuel-index-entity button.templateWidgetAdd:before {
    content: "\f067";
    font-family: "FontAwesome";
    margin-right: 5px;
    font-size: 11px;
}

#fuel-index-entity button.templateWidgetAdd:hover {
    background-color: #000;
    border-color: #000;
}

#fuel-index-entity .fuel-index ul.widgetTemplateRequired {
    margin-bottom: -5px;
}

#fuel-index-entity .form-horizontal fieldset .widgetItem label.control-label {
    display: none;
}

#fuel-index-entity .form-horizontal .widgetHeading {
    padding: 0 20px;
}

#fuel-index-entity .form-horizontal .widgetHeading > * {
    float: left;
    width: 33.33% !important;
    margin-bottom: 0;
    margin-top: 5px;
}

#fuel-index-entity .form-horizontal .fuel-index .widgetHeading > * {
    width: 18% !important;
}

#fuel-index-entity li.widgetItem:first-child {
    margin-top: 5px;
}

#fuel-index-entity li.widgetItem {
    padding: 6px 20px;
    padding-bottom: 5px;
}

@media all and (max-width: 767px) {
    #fuel-index-entity .widgetItem div[data-validation=start],
    #fuel-index-entity .widgetItem div[data-validation=end],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentLocker],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentOvs],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentNovs] {
        width: 100%;
        float: none;
    }

    #fuel-index-entity .widgetItem div[data-validation=start],
    #fuel-index-entity .widgetItem div[data-validation=end],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentLocker],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentOvs],
    #fuel-index-entity .widgetItem div[data-validation=indexPercentNovs] {
        padding-right: 0;
    }

    #fuel-index-entity button.templateWidgetAdd {
        top: 9px;
    }

    #fuel-index-entity li.widgetItem {
        overflow: hidden;
    }

    #fuel-index-entity fieldset legend {
        margin-bottom: 5px;
    }

    #fuel-index-entity .form-horizontal .widgetHeading {
        display: none;
    }
}

 #fuel-index-entity .alert {
     margin: 15px 0 0 0;
 }

/* awp expeditii */
#awb-entity div[data-validation=packageWeight],
#awb-entity div[data-validation=address] {
  margin-top: 0;
  margin-bottom: 0;
}

#awb-entity #sender .option-adder-add {
  background-color: #353535;
  border: none;
  padding: 3px 7px;
}

#awb-entity #sender .option-adder-add:hover,
#awb-entity #sender .option-adder-add:active,
#awb-entity #sender .option-adder-add:focus {
  background-color: #656363;
  border: none;
}

#awb-entity #sender div[data-validation=phoneNumber],
#awb-entity #receiver-data div[data-validation=phoneNumber]{
  margin-top: 0;
}

#awb-entity #awbParcels .widgetItem:not(:last-child) {
  margin-bottom: 1px;
  border-bottom: none;
}

#awb-entity .form-horizontal .widgetHeading {
  padding: 0 20px;
}

#awb-entity .form-horizontal .widgetHeading > * {
  margin-bottom: 0;
  width: 16.6666667%;
  padding: 0 15px;
}

#awb-entity #parcelDetails .widgetTemplateRequired > li.widgetItem {
  padding: 6px 20px;
  padding-bottom: 5px;
}

#awb-entity #awbParcels .col-lg-2 {
  width: 16.66666667%;
  float: left;
}

#awb-entity #parcelDetails .widgetTemplateRequired > li.widgetItem div[data-validation=parcelNumber] .col-lg-2,
#awb-entity #parcelDetails .widgetTemplateRequired > li.widgetItem > .clearfix > .col-lg-2:first-child,
#awb-entity .form-horizontal .widgetHeading > .control-label:first-child {
  width: 20% !important;
}

@media all and (max-width: 991px) {
  #awb-entity #awbParcels .widgetItem > div > div > div {
    width: 100%;
    margin-bottom: 3px;
    float: left;
    padding: 0;
  }

  #awb-entity .form-horizontal .widgetHeading {
    display: none;
  }

  #awbParcels .widgetItem {
    float: none !important;
  }


  #awb-entity #parcelDetails .widgetTemplateRequired > li.widgetItem div[data-validation=parcelNumber] .col-lg-2,
  #awb-entity #parcelDetails .widgetTemplateRequired > li.widgetItem > .clearfix > .col-lg-2:first-child,
  #awb-entity .form-horizontal .widgetHeading > .control-label:first-child {
    width: 100% !important;
  }
}

form[data-prototype] button.collectionWidgetAdd {
  position: absolute;
  right: 78px;
  top: -36px;
  z-index: 3;
  font-size: 13px;
}

#invoice_ledgers .download-ledger {
  margin-left: 20px;
}

.has-error .select2-selection {
  border-color: #ed5565 !important;
}

.alert {
  margin: 0 0 20px 0;
}
.alert .close {
  right: -10px;
}

.no-email-alert {
    display: none;
}

/* inline edit widget */
[data-inline-edit] {
  display: inline-block;
}
.navbar-header {
  margin-right: 5px;
}

.middle-title {
  font-size: 20px;
  font-weight: bold;
  color: #676a6c;
  padding-top: 10px;
  float: left;
}

.navbar-top-links {
  text-align: right;
}

.label {
  font-size: 12px;
  display: inline-block;
  margin: 2px;
  line-height: 15px;
}

.label.label-primary {
  background-color: #a00a0f;
}

.label-primary[href]:focus, .label-primary[href]:hover {
  background-color: #ed1f24;
}

[data-image-gallery] {
  display: inline-block;
  font-size: 13px;
  position: relative;
}

[data-image-gallery].no-results {
  cursor: default;
  color: black;
}

[data-image-gallery].loading {
  pointer-events: none;
  color: transparent;
}

[data-image-gallery].error {
  cursor: default;
  color: red;
}

// fix for fixed invoice form ui
.billed-services {
  [data-validation=serviceType] {
    float: left;
    width: 100%;
  }
  .widgetItem {
    position: relative;
  }
  .templateWidgetRemove {
    position: absolute;
    top: 8px;
    right: 3px;
  }
}

#status-update-form-container {
  padding-top: 30px;
}

#status-update-form-container [data-select-parcels] {
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
}
